body {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    margin: 0;
    margin-bottom: 30px;
}

a {
    color: rgb(0, 76, 152) !important;
    border-bottom: 1px solid rgba(0, 76, 152, 0.2);
}

a:hover {
    border-bottom: 1px solid rgba(0, 76, 152, 0.8);
    text-decoration: none !important;
}

.btn {
    background-color: rgba(0, 76, 152, 1);
    border-radius: 40px;
    color: #FFF !important;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9rem;
    line-height: 1.2;
    outline: none;
    padding: 6px 30px;
    text-decoration: none !important;
}

.btn:hover {
    background-color: rgba(0, 76, 152, 0.85);
    color: #FFF;
}

.home-wrapper h1 {
    border-bottom: 1px solid #EEE;
    font-size: 4rem;
    font-weight: 400;
    margin: 40px auto 30px;
    max-width: 500px;
    padding-bottom: 50px;
    padding-top: 40px;
    text-align: center;
}

.home-wrapper p {
    font-size: 1.6rem;
    font-weight: 300;
    margin-bottom: 50px;
    text-align: center;
}

.home-wrapper p sup {
    font-size: 0.7rem;
}

.home-wrapper .hero {
    align-items: center;
    box-shadow: inset 0 10px 20px -10px rgba(0,0,0, 0.4), inset 0 -10px 20px -10px rgba(0,0,0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.home-wrapper .hero .bg-img {
    background-image: url(../images/home-hero.jpg);
    background-position: center;
    background-size: cover;
    filter: blur(5px);
    height: 100%;
    opacity: 0.15;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.home-wrapper .hero p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 35px 0 50px;
    text-shadow: 0px 1px 1px #FFF;
    width: 90%;
    z-index: 10;
}

.home-wrapper .hero .btn {
    border: 1px solid rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 3px rgba(0, 76, 152, 0.85), 0 6px 12px -5px rgba(0, 0, 0, 0.5);
    font-size: 1.4rem;
    font-weight: 200;
    margin-bottom: 50px;
    padding: 15px 80px;
    text-decoration: none;
    z-index: 10;
}

section.content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px 5%;
}

section.content h2 {
    margin-top: 50px;
}

section.content dt {
    font-size: 1rem;
    font-weight: 600;
    margin: 30px 0 10px;
}

section.content dd {
    border-left: 1px solid #dadada;
    margin: 0;
    padding-left: 10px;
}

section.content dd p {
    margin: 5px 0 15px;
    line-height: 1.6;
}

.test-drive {
    background-color: #f1f1f1;
    border-radius: 20px;
    margin: 30px auto 50px;
    max-width: 750px;
    padding: 10px 20px 25px;
    text-align: center;
}

.test-drive .btn {
    margin: 10px 0 0;
}

